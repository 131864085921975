<template>
  <v-container fluid>Subscription</v-container>
</template>

<script>
export default {
  name: 'Subscription',
}
</script>

<style scoped></style>
